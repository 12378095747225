<template>
  <section class="hero is-fullheight">
    <ModalFaq
      typeModal="edit"
      v-if="activeModalEditFaq"
      :active="activeModalEditFaq"
      titleModal="EDITAR PREGUNTA"
      :loading="loading"
      :idUpdate="questionSelect.id"
      :question="questionSelect.question"
      :answer="questionSelect.answer"
      :status="questionSelect.active"
      @close-modal="resetModalEdit"
      @set-data-action="updateFaqAction"
    />
    <ModalFaq
      typeModal="add"
      v-if="activeModalAddFaq"
      :active="activeModalAddFaq"
      :loading="loading"
      titleModal="AGREGAR PREGUNTA"
      @close-modal="$emit('close-modal')"
      @set-data-action="addFaqAction"
    />
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left" />
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input
                  class="input input_reset"
                  type="text"
                  placeholder="Buscador"
                  v-model="searchText"
                  @input="searchFaqs"
                >
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="faqsList"
                :loading=loading
              >
                <div slot="answer" slot-scope="scope">
                  <p class="answer_text" v-html="scope.row.answer" />
                </div>
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
                <div class="is-flex is-justify-content-center is-align-items-center" slot="action" slot-scope="scope">
                  <img class="action_img" src="@/assets/icon/edit_icon_pen.png" alt="Editar" @click="showDetailAction(scope.row)">
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'FrequentQuestionsMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue'),
    ModalFaq: () => import('@/components/FrequentQuestions/ModalFaq.vue')
  },
  data () {
    return {
      loading: false,
      page: 1,
      pages: 1,
      searchText: '',
      columnsData: [
        { id: 'question', header: 'Pregunta' },
        { id: 'answer', name: 'answer', header: 'Respuesta' },
        { id: 'status', name: 'status', header: 'Estatus', class: 'is-vcentered' },
        { id: 'action', name: 'action', header: 'Acciones', class: 'has-text-centered is-vcentered' }
      ],
      faqsList: [],
      activeModalEditFaq: false,
      questionSelect: {}
    }
  },
  props: {
    activeModalAddFaq: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['getFaqs', 'createFaq', 'updateFaq']),
    async getFaqsList (page, search) {
      const resp = await this.getFaqs({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search })
      this.pages = resp.totalPages
      this.faqsList = resp.faqs
      this.loading = false
    },
    searchFaqs ({ target }) {
      this.page = 1
      if (target.value.length >= 3 || target.value.length === 0) {
        this.loading = true
        this.$router.push(
          { name: 'preguntas-frecuentes', query: { page: this.page, search: target.value } }
        ).catch(() => {})
        this.searchText = target.value.length === 0 ? '' : target.value
        this.getFaqsList(this.page, target.value)
      }
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.$router.push({ name: 'preguntas-frecuentes', query: { page: this.page, search: this.searchText } })
      this.getFaqsList(this.page, this.searchText)
    },
    showDetailAction (event) {
      this.questionSelect = event
      this.activeModalEditFaq = true
    },
    resetModalEdit () {
      this.questionSelect = {}
      this.activeModalEditFaq = false
    },
    async addFaqAction (dataCreate) {
      this.loading = true
      const resp = await this.createFaq(dataCreate)
      if (resp) {
        this.$emit('close-modal')
        this.page = 1
        this.searchText = ''
        this.getFaqsList(this.page, this.searchText)
      }
      this.loading = false
    },
    async updateFaqAction (dataUpdate) {
      this.loading = true
      const { success } = await this.updateFaq(dataUpdate)
      if (success) {
        this.resetModalEdit()
        this.getFaqsList(this.page, this.searchText)
      }
      this.loading = false
    }
  },
  beforeMount () {
    this.loading = true
    const { page, search } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.searchText = search === '' ? this.searchText : search
    this.getFaqsList(this.page, this.searchText)
  }
}
</script>

<style lang="scss" scoped>
  .level-item {
    justify-content: flex-start;
  }

  .action_img {
    width: 15px;
    margin: auto 10px;
    cursor: pointer;
  }

  .answer_text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
